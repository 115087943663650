import Styles from './Card.module.css';
import React from 'react';
import { animated } from 'react-spring';
import { ButtonComponent } from './ButtonComponent';

function Card(props) {
  const { adId, imagen, ctaBtnType, ctaBtnText, ctaBtnLink } = props;

  return (
    <animated.div
      className={Styles.card}
      style={{
        backgroundImage: `url(${imagen})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="text-center">
        <div className={`${Styles.actionBtn}`}>
          <a href={ctaBtnLink} target="_blank" rel="noreferrer">
            <ButtonComponent type={ctaBtnType} text={ctaBtnText} adId={adId} />
          </a>
        </div>
      </div>
    </animated.div>
  );
}

export default Card;
