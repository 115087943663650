import { Button } from 'antd';
import React from 'react';
import {
  GlobalOutlined,
  MessageOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import './ButtonComponent.css';
import { useParams } from 'react-router-dom';
import API from '../services/API';

export const ButtonComponent = ({ type, text, adId }) => {
  const { id } = useParams();

  const updateViewCount = async (adId) => {
    try {
      await API.post('/api/analytical-data', {
        cta_clicked: true,
        ad_id: adId,
        campaign_id: id,
      }).then((res) => {
        console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  switch (type) {
    case 'website':
      return (
        <Button
          size="large"
          type="primary"
          className="w-100"
          onClick={() => updateViewCount(adId)}
        >
          <div className="d-flex justify-content-center">
            <div style={{ marginTop: '-3.5px', marginRight: '5px' }}>
              <GlobalOutlined />
            </div>
            {text}
          </div>
        </Button>
      );
    case 'sms':
      return (
        <Button
          size="large"
          type="ghost"
          className="w-100 sms-btn"
          onClick={() => updateViewCount(adId)}
        >
          <div className="d-flex justify-content-center">
            <div style={{ marginTop: '-3.5px', marginRight: '5px' }}>
              <MessageOutlined />
            </div>
            {text}
          </div>
        </Button>
      );
    case 'call':
      return (
        <Button
          size="large"
          type="ghost"
          className="w-100 call-btn"
          onClick={() => updateViewCount(adId)}
        >
          <div className="d-flex justify-content-center">
            <div style={{ marginTop: '-3.5px', marginRight: '5px' }}>
              <PhoneOutlined />
            </div>
            {text}
          </div>
        </Button>
      );
    case 'whatsapp':
      return (
        <Button
          size="large"
          type="ghost"
          className="w-100 whatsapp-btn"
          onClick={() => updateViewCount(adId)}
        >
          <div className="d-flex justify-content-center">
            <div style={{ marginTop: '-3.5px', marginRight: '5px' }}>
              <WhatsAppOutlined />
            </div>
            {text}
          </div>
        </Button>
      );
    case 'custom':
      return (
        <Button
          size="large"
          type="ghost"
          className="w-100 custom-btn"
          onClick={() => updateViewCount(adId)}
        >
          {text}
        </Button>
      );
    default:
      break;
  }
};
