import React, { useState, useEffect } from 'react';
import Carousel from './Carsousel';
import Card from './Card';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import API from '../services/API';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

let cards = [];

function Campaign() {
  const { id } = useParams();
  const [campaignDataLoading, setCampaignDataLoading] = useState(true); // set true as default
  const [mappedAds, setMappedAds] = useState([]);
  const [mappedAdsLoading, setMappedAdsLoading] = useState(true); // set true as default
  const [isAdsProcessing, setIsAdsProcessing] = useState(true); // set true as default
  const [welcomeCard, setWelcomeCard] = useState('');

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 28,
      }}
      spin
    />
  );

  useEffect(() => {
    fetchCampaignData();
    fetchMappedAds();
  }, []);

  const fetchCampaignData = async () => {
    try {
      await API.get(`/api/opening/${id}`).then((res) => {
        setWelcomeCard(res.data.welcome_card_url);
        setCampaignDataLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMappedAds = async () => {
    try {
      await API.get(`/api/mapper/campaign/${id}`).then((res) => {
        if (res.status === 200) {
          setMappedAds(res.data);
          setMappedAdsLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // after fetching mapped ads and campaign welcome card, process them into cards
  useEffect(() => {
    if (!mappedAdsLoading && !campaignDataLoading) {
        cards = []; // make cards array empty

        if(mappedAds.length > 0) {
          mappedAds?.forEach((element) => {
            let adObj = {
              key: uuidv4(),
              content: (
                <Card
                  adId={element?.id}
                  imagen={element?.img_url}
                  ctaBtnType={element?.cta_type}
                  ctaBtnText={element?.button_type}
                  ctaBtnLink={element?.cta_url}
                />
              ),
            };
  
            cards.push(adObj);
          });
        }

        if(welcomeCard) {
          let welcome = {
            key: uuidv4(),
            content: (
              <Card
                adId={null}
                imagen={welcomeCard}
                ctaBtnType={null}
                ctaBtnText={null}
                ctaBtnLink={null}
              />
            ),
          };

          cards.unshift(welcome); // set welcome card at 0th index of the array
        }
        setIsAdsProcessing(false);
    }
  }, [mappedAdsLoading, campaignDataLoading, mappedAds, welcomeCard]);

  // let cards = [
  //   {
  //     key: uuidv4(),
  //     content: (
  //       <Card
  //         adId={null}
  //         imagen="https://i.ibb.co/RPpMmS3/SWIPE-CARD-01.jpg"
  //         ctaBtnType={null}
  //         ctaBtnText={null}
  //         ctaBtnLink={null}
  //       />
  //     ),
  //   },
  //   {
  //     key: uuidv4(),
  //     content: (
  //       <Card
  //         imagen="https://i.ibb.co/9brTVfF/SWIPE-CARD-03.jpg"
  //         ctaBtnType="sms"
  //         ctaBtnText="SMS US NOW"
  //         ctaBtnLink="sms:1234567890&body=Hello%20there,%20I%20am%20interested%20in%20your%20product.%20Please%20provide%20more%20information."
  //       />
  //     ),
  //   },
  //   {
  //     key: uuidv4(),
  //     content: (
  //       <Card
  //         imagen="https://i.ibb.co/p0X4sqC/SWIPE-CARD-04.jpg"
  //         ctaBtnType="call"
  //         ctaBtnText="CALL US NOW"
  //         ctaBtnLink="tel:123-456-7890"
  //       />
  //     ),
  //   },
  //   {
  //     key: uuidv4(),
  //     content: (
  //       <Card
  //         imagen="https://i.ibb.co/mJhzswZ/SWIPE-CARD-05.jpg"
  //         ctaBtnType="custom"
  //         ctaBtnText="WATCH VIDEO"
  //         ctaBtnLink="https://youtu.be/bANfnYDTzxE"
  //       />
  //     ),
  //   },
  //   {
  //     key: uuidv4(),
  //     content: (
  //       <Card
  //         imagen="https://i.ibb.co/DDn7GB1/SWIPE-CARD-02.jpg"
  //         ctaBtnType="whatsapp"
  //         ctaBtnText="WHATSAPP US NOW"
  //         ctaBtnLink="https://wa.me/message/RHHOQZWQEHLZJ1?src=qr"
  //       />
  //     ),
  //   },
  //   {
  //     key: uuidv4(),
  //     content: (
  //       <Card
  //         imagen="https://i.ibb.co/xX6h5xR/SWIPE-CARD-06.jpg"
  //         ctaBtnType="sms"
  //         ctaBtnText="SMS US NOW"
  //         ctaBtnLink="sms:1234567890&body=Hello%20there,%20I%20am%20interested%20in%20your%20product.%20Please%20provide%20more%20information."
  //       />
  //     ),
  //   },
  // ];

  return (
    <div>
      {isAdsProcessing || mappedAdsLoading  || campaignDataLoading? (
        <div className="center">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <Carousel
          cards={cards}
          height="88vh"
          width="10vw"
          margin="auto"
          offset={2}
          showArrows={false}
        />
      )}
    </div>
  );
}

export default Campaign;
