import Carousel from 'react-spring-3d-carousel';
import { useState, useEffect } from 'react';
import { config } from 'react-spring';
import { useParams } from 'react-router-dom';
import API from '../services/API';

let xDown = 0;
let yDown = 0;

export default function Carroussel(props) {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [cards] = useState(table);
  const { id } = useParams();

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        handleNext();
      } else {
        /* right swipe */
        handleBack();
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };

  const handleNext = () => {
    if (goToSlide < cards.length - 1) {
      const arrayIndex = goToSlide + 1;
      setGoToSlide(arrayIndex);
      if (cards[arrayIndex]?.content?.props?.adId) {
        updateViewCount(cards[arrayIndex]?.content?.props?.adId);
      }
    } else {
      setGoToSlide(0);
    }
  };

  const handleBack = () => {
    if (goToSlide >= 1) {
      const arrayIndex = goToSlide - 1;
      setGoToSlide(arrayIndex);
      if (cards[arrayIndex]?.content?.props?.adId) {
        updateViewCount(cards[arrayIndex]?.content?.props?.adId);
      }
    } else {
      const arrayIndex = cards.length - 1;
      setGoToSlide(arrayIndex);
      if (cards[arrayIndex]?.content?.props?.adId) {
        updateViewCount(cards[arrayIndex]?.content?.props?.adId);
      }
    }
  };

  const updateViewCount = async (adId) => {
    try {
      await API.post('/api/analytical-data', {
        cta_clicked: false,
        ad_id: adId,
        campaign_id: id,
      }).then((res) => {
        console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.slow}
      />
    </div>
  );
}
