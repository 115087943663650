import { Layout } from 'antd';
import Header from './components/Header';
import Footer from './components/Footer';
import Campaign from './components/Campaign';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const { Content } = Layout;

  return (
    <div className="App">
      <Router>
        <Layout className="layout" style={{ height: '100vh' }}>
          {/* <Header /> */}

          <Content>
            <Routes>
              <Route path="/:id" exact element={<Campaign />} />
            </Routes>
          </Content>

        <Footer />
        </Layout>
      </Router>
    </div>
  );
}

export default App;
