import React from 'react';
import { Layout } from 'antd';

function Footer() {
  const { Footer } = Layout;

  return (
    <Footer className='text-center' style={{background: '#fff'}}>
      Terms of Use | Help
    </Footer>
  );
}

export default Footer;
